<template>
  <div class="container-dk-dk">
    <audio id="attended" @ended="soundEmmited">
      <source src="@/assets/sounds/SD_ALERT_24.mp3" type="audio/mpeg" />
    </audio>
    <div
      v-if="loadingAll"
      class="d-flex"
      style="align-items: center; justify-content: center; padding-top: 2rem"
    >
      <h5 style="font-weight: normal">Cargando</h5>
      <Loading></Loading>
    </div>
    <div
      v-else
      class="d-flex dk-group-flex justify-center"
      style="justify-content: space-between"
    >
      <!-- <p v-if="loadingCall">Cargando . . .</p> -->
      <!-- <loading-page v-if="loadingCall"></loading-page> -->
      <div :class="videoCallSilverState ? 'Panel_Video' : 'Panel'">
        <video-call ref="videoCall" id="Video-call"></video-call>
        <div
          :class="
            videoCallSilverState
              ? 'd-flex justify-space-between dk-group-video'
              : 'd-flex justify-space-between dk-group'
          "
          v-if="!!!videoCallSilverState && !errorForce"
        >
          <v-card
            elevation="0"
            :class="
              videoCallSilverState
                ? 'badge-dk-video justify-center d-flex align-center'
                : 'badge-dk white--text justify-center d-flex align-center'
            "
            style="
              margin-right: 0.5rem;
              max-width: 32%;
              width: 100%;
              text-align: center;
            "
          >
            <h1>Puesto de atención - {{ selectedBranch.label }}</h1>
          </v-card>
          <v-card
            elevation="0"
            class="p-2 badge-dk"
            :class="
              videoCallSilverState ? 'badge-dk-video p-2' : 'p-2 badge-dk'
            "
            style="width: 100%; max-width: 100%"
          >
            <div class="d-flex" style="flex-wrap: wrap">
              <div class="d-flex align-center">
                <span>Atendidos:</span>
                <!-- <div class="badge">{{ datastatsWorker.ticket }}</div> -->
                <div class="badge" v-if="turns_attended[today]">
                  {{ turns_attended[today].length }}
                </div>
                <div v-else class="badge">{{ datastatsWorker.ticket }}</div>
              </div>
              <div class="d-flex align-center">
                <span>Prom. espera:</span>
                <div class="badge">{{ 0 }} min</div>
              </div>
              <div class="d-flex align-center">
                <span>Prom. atención:</span>
                <div class="badge">{{ datastatsWorker.timeAtt }} min</div>
              </div>
            </div>
          </v-card>
        </div>
        <div v-if="!errorForce" class="GridColumns">
          <!-- <div class="d-flex justify-space-between mt-5" v-if="callData">
            <p>
              NOMBRE: {{ callData.customer.firstName }}
              {{ callData.customer.lastName || "" }}
            </p>
            <p>
              {{ callData.customer.cuit }} :
              {{ callData.customer.cedulaIdentidad }}
            </p>
            <p v-if="callData.customer.dni">DNI: {{ callData.customer.dni }}</p>
            <div v-html="callData.extraFields.Videollamada"></div>
          </div> -->
          <div
            :class="
              videoCallSilverState
                ? 'd-flex justify-space-between dk-group-video'
                : 'mt-2 d-flex justify-space-between dk-group'
            "
            style="align-items: stretch !important"
          >
            <v-card
              elevation="0"
              :class="
                videoCallSilverState
                  ? 'badge-dk-video nth-2-video-badge'
                  : 'badge-dk'
              "
              :style="
                videoCallSilverState
                  ? 'max-width: 50%; width: 100%; text-align: center; padding: 0; border-right: 1px solid #ddd;'
                  : 'max-width: 27%; width: 100%; text-align: center; padding: 0; border-right: 1px solid #ddd;'
              "
            >
              <div v-if="!!!callData">
                <h2
                  v-if="possibleStatesOfflineTimeEvaluation"
                  style="color: #f35858"
                >
                  {{ totalCounterAllInactivity }}
                </h2>
              </div>
              <v-card-text style="padding: 1rem">
                <div class="align-baseline flex-wrap ContainerCards">
                  <span style="max-width: 60px">Nombre</span>
                  <div
                    style="
                      padding: 0;
                      margin-left: 3rem;
                      color: black;
                      font-size: 16px;
                    "
                  >
                    {{ userData }}
                  </div>
                </div>
                <div class="align-baseline flex-wrap ContainerCards">
                  <span style="max-width: 50px">Puesto</span>
                  <v-text-field
                    v-model="boxPlace"
                    readonly
                    class="p-0"
                    style="
                      padding: 0;
                      margin-left: 2rem;
                      max-width: 250px;
                      width: 100%;
                    "
                  ></v-text-field>
                </div>
                <div class="align-baseline ContainerCards">
                  <span style="max-width: 50px">Sala de espera</span>
                  <v-select
                    :items="optionsSelectInputWaitingRoom"
                    class="p-0"
                    v-model="selectOptionWaitingRoom"
                    :disabled="!!callData"
                    style="
                      padding: 0;
                      margin-left: 2rem;
                      max-width: 250px;
                      width: 100%;
                    "
                  ></v-select>
                </div>
                <div class="align-baseline flex-wrap ContainerCards">
                  <span style="max-width: 50px">Estado</span>
                  <v-select
                    :items="optionsSelectInputState"
                    item-text="name"
                    v-model="selectOptionState"
                    @change="handleChangeState"
                    :disabled="loadingState || loadingChangeState"
                    class="p-0"
                    style="
                      padding: 0;
                      margin-left: 2rem;
                      max-width: 250px;
                      width: 100%;
                    "
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item
                        v-bind="attrs"
                        v-on="on"
                        v-show="'Conectado - En atención' !== item.value"
                      >
                        <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item.value || item.name || item"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>
                <div class="align-baseline flex-wrap ContainerCards">
                  <span style="max-width: 70px">Perfil</span>
                  <div
                    style="
                      padding: 0;
                      margin-left: 3rem;
                      color: black;
                      font-size: 16px;
                    "
                  >
                    {{ profile }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-card
              elevation="0"
              :class="
                videoCallSilverState
                  ? 'badge-dk-video nth-3-video-badge'
                  : 'badge-dk'
              "
              :style="
                videoCallSilverState
                  ? 'max-width: 50%; width: 100%; text-align: center; padding: 0; margin-left: 0.5rem;'
                  : 'max-width: 100%; width: 100%; text-align: center; padding: 0; margin-left: 0.5rem;'
              "
            >
              <VuePerfectScrollbar
                class="scroll-area"
                :style="videoCallSilverState && 'max-height: 550px'"
                :settings="settings"
              >
                <!-- <h2>Acciones</h2> -->
                <v-card-text style="padding: 1rem">
                  <div
                    :class="
                      videoCallSilverState
                        ? 'd-flex justify-space-between dk-group-video yoshua_video'
                        : 'd-flex justify-space-between dk-group yoshua_video'
                    "
                    :style="
                      videoCallSilverState
                        ? 'flex-wrap: wrap !important; padding: 1rem; overflow-x: hidden;'
                        : ''
                    "
                  >
                    <div
                      style="max-width: 100%; width: 100%; margin-right: 1rem"
                      v-if="$refs.finishCall && !$refs.finishCall.showTypify"
                    >
                      <p><strong>Llamar nuevo turno</strong></p>
                      <v-btn
                        v-if="config && config.nextBtnEnabled"
                        color="success"
                        block
                        style="margin: 1rem; display: block"
                        @click="handleCallNextTurn(false)"
                        :disabled="
                          !!callData ||
                          updateStateValue !== 'Conectado - Disponible'
                        "
                        >Llamar siguiente</v-btn
                      >
                      <transition
                        name="fade"
                        v-if="config && config.callBtnEnabled"
                      >
                        <v-btn
                          color="success"
                          block
                          style="display: block; margin: 1rem"
                          @click="handleVisibleSearch"
                          :disabled="
                            !!callData ||
                            updateStateValue !== 'Conectado - Disponible'
                          "
                          v-if="!visibleSearch && !visibleSecondSearch"
                          >Llamar turno</v-btn
                        >
                        <div v-else-if="visibleSearch && !visibleSecondSearch">
                          <v-text-field
                            v-model="search"
                            label="Buscar:"
                            class="p-0"
                            style="padding: 0; margin-left: 2rem"
                          ></v-text-field>
                          <template v-if="searchResults.length">
                            <p
                              v-for="(result, index) in searchResults"
                              :key="index"
                              style="cursor: pointer"
                              @click="() => handlerCallSingularTurn(result)"
                            >
                              {{ result.name }}
                            </p>
                          </template>
                          <p v-else>No hay resultados</p>
                          <v-btn @click="visibleSearch = false">Cancelar</v-btn>
                        </div>
                        <div v-else>
                          <v-text-field
                            v-model="searchSecond"
                            label="Buscar:"
                            class="p-0"
                            style="padding: 0; margin-left: 2rem"
                          ></v-text-field>
                          <template v-if="searchSecondResults.length">
                            <p
                              v-for="(result, index) in searchSecondResults"
                              :key="index"
                              style="cursor: pointer"
                              @click="() => callSingularTurnById(result)"
                            >
                              {{ result.letter + result.number }}
                            </p>
                          </template>
                          <p v-else>No hay resultados</p>
                          <v-btn @click="closeCall">Cancelar</v-btn>
                        </div>
                      </transition>

                      <v-btn
                        v-if="config && config.reCallBtnEnabled"
                        :disabled="
                          !!!callData ||
                          updateStateValue !== 'Conectado - Disponible'
                        "
                        color="success"
                        block
                        style="margin: 1rem; display: block"
                        @click="reCallTurnAction"
                        >Volver a llamar</v-btn
                      >

                      <transition
                        name="fade"
                        v-if="config && config.searchTurnBtnEnabled"
                      >
                        <v-btn
                          color="info"
                          block
                          style="margin: 1rem; display: block"
                          @click="handleVisibleSearchAll"
                          :disabled="
                            !!callData ||
                            updateStateValue !== 'Conectado - Disponible'
                          "
                          v-if="!showAllTurns"
                          >Buscar</v-btn
                        >
                        <div v-else>
                          <div
                            name="fade2"
                            v-if="config && config.callBtnEnabled"
                          >
                            <div
                              v-if="
                                !showSearchAllTurns && !showSearchAbstenTurns
                              "
                            >
                              <v-btn
                                block
                                style="margin: 1rem; display: block"
                                @click="handleSearchAllturns"
                                :disabled="
                                  !!callData ||
                                  updateStateValue !== 'Conectado - Disponible'
                                "
                                v-if="config && config.chooseTurnBtnEnabled"
                                >Buscar Turno</v-btn
                              >
                            </div>
                          </div>
                          <div
                            name="fade3"
                            v-if="configs && configs.searchMissingBtnEnabled"
                          >
                            <div>
                              <v-btn
                                block
                                style="margin: 1rem; display: block"
                                @click="handleSearchAbsent"
                                :disabled="
                                  !!callData ||
                                  updateStateValue !== 'Conectado - Disponible'
                                "
                                v-if="
                                  !showSearchAbstenTurns && !showSearchAllTurns
                                "
                                >Buscar Ausente</v-btn
                              >
                            </div>
                          </div>
                          <!-- <v-text-field
                            v-model="search"
                            label="Buscar:"
                            class="p-0"
                            style="padding: 0; margin-left: 2rem"
                          ></v-text-field>
                          <p v-for="(result, index) in searchResults" :key="index">
                            {{ result }}
                          </p>
                          <p v-if="!searchResults.length">No hay resultados</p>
                          <v-btn @click="visibleSearch = false">Cancelar</v-btn> -->
                        </div>
                      </transition>
                    </div>
                    <!-- Finish Buttons wth typify -->
                    <finish-buttons
                      ref="finishCall"
                      @finishCall="handleFinishCall"
                      @reset="finishDerivate"
                      :callData="callData"
                      :pollingData="pollingData"
                      :loggedData="loggedUser"
                    ></finish-buttons>
                    <!-- ------- -->
                    <div
                      style="max-width: 100%; width: 100%; margin-right: 1rem"
                    >
                      <p><strong>Control</strong></p>
                      <v-btn
                        v-if="config && config.queuedDetails"
                        block
                        style="
                          margin: 1rem;
                          white-space: normal;
                          display: block;
                        "
                        @click="$refs.glued.open()"
                        color="secondary"
                        >Ver detalles encolados</v-btn
                      >

                      <v-btn
                        v-if="config && config.cancelCallBtnEnabled"
                        block
                        style="
                          margin: 1rem;
                          white-space: normal;
                          color: white;
                          display: block;
                        "
                        @click="handlerCancelCall(false)"
                        color="danger"
                        :disabled="
                          !!!callData &&
                          updateStateValue !== 'Conectado - En atención'
                        "
                        >Cancelar llamado</v-btn
                      >

                      <v-btn
                        v-if="config && config.reQueueBtnEnabled"
                        block
                        style="
                          margin: 1rem;
                          white-space: normal;
                          display: block;
                        "
                        @click="handlerRequeue"
                        color="secondary"
                        outlined
                        :disabled="
                          !!!callData &&
                          updateStateValue !== 'Conectado - En atención'
                        "
                        >Colocar al final</v-btn
                      >
                      <v-btn
                        v-if="config && config.cancelDerivateBtnEnabled"
                        block
                        style="
                          margin: 1rem;
                          white-space: normal;
                          display: block;
                        "
                        color="secondary"
                        :disabled="
                          !derivableCancelData.dataDerivaion['turn.id'] ||
                          (callData &&
                            derivableCancelData.dataDerivaion &&
                            callData.id !=
                              derivableCancelData.dataDerivaion['turn.id']) ||
                          updateStateValue !== 'Conectado - Disponible'
                        "
                        @click="handleCancelDerivate"
                        :loading="derivableCancelData.loading"
                        >Cancelar derivación</v-btn
                      >
                    </div>
                  </div>
                </v-card-text>
              </VuePerfectScrollbar>
            </v-card>
          </div>
          <div
            :class="
              videoCallSilverState
                ? 'd-flex justify-space-between dk-group-video'
                : 'dk-group d-flex justify-space-between'
            "
            style="margin: 0.5rem auto"
          >
            <v-card
              elevation="0"
              :class="videoCallSilverState ? 'badge-dk-video' : 'badge-dk'"
              style="
                max-width: 100%;
                width: 100%;
                text-align: center;
                padding: 1rem 0;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              color="white"
              rounded="0"
            >
              <call-detail-customer ref="detailCustomer"></call-detail-customer>
              <span>
                Atendiendo a
                <p style="font-weight: bold">{{ atended }}</p>
              </span>
            </v-card>
            <v-card
              elevation="0"
              :class="videoCallSilverState ? 'badge-dk-video' : 'badge-dk'"
              style="
                max-width: 100%;
                width: 100%;
                text-align: center;
                padding: 1rem 0;
              "
              color="white"
              rounded="0"
            >
              Tiempo de espera
              <p v-if="!!callData" style="font-weight: bold">
                {{ showTotalStanByTime | secondsToHours }}
              </p>
            </v-card>
            <v-card
              elevation="0"
              :class="videoCallSilverState ? 'badge-dk-video' : 'badge-dk'"
              style="
                max-width: 100%;
                width: 100%;
                text-align: center;
                padding: 1rem 0;
              "
              color="white"
              rounded="0"
            >
              Tiempo de atención
              <p v-if="!!callData" style="font-weight: bold">
                {{ showPanelSeconds | secondsToHours }}
              </p>
            </v-card>
            <v-card
              elevation="0"
              :class="videoCallSilverState ? 'badge-dk-video' : 'badge-dk'"
              style="
                max-width: 100%;
                width: 100%;
                text-align: center;
                padding: 1rem 0;
              "
              v-if="!!callData"
              color="white"
              rounded="0"
            >
              Hora turno emitido
              <p v-if="!!callData" style="font-weight: bold">
                {{ emitedHour }}
              </p>
            </v-card>
          </div>
          <formativity
            :queueGroups="queueGroups"
            :selectOptionState="selectOptionState"
            v-if="showFormativity"
          ></formativity>
        </div>
        <div v-else>
          <h2 class="text-center">
            Este puesto de atención ya está ocupado ¿quiere entrar en él de
            todos modos?
          </h2>
          <div class="buttons d-flex justify-center">
            <v-btn @click="$router.push({ path: '/home/' })"> Cancelar </v-btn>
            <v-btn color="error" class="ml-3" @click="() => getAllData('force')"
              >Forzar</v-btn
            >
          </div>
        </div>
        <v-snackbar v-model="snackbar">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <!-- for glued -->
      <glued-detail ref="glued"></glued-detail>
      <v-dialog
        v-model="showModalToCallAuto"
        persistent
        max-width="450"
        class="p-0"
      >
        <v-card class="text-center p-0">
          <v-card-title class="pt-3 m-2 dk-header">
            <strong>ASIGNACIÓN AUTOMÁTICA DE TURNO</strong>
            <!-- <button
              @click="handlecloseCancell"
              style="background: none; border: none; outline: none"
            >
              <v-icon>mdi-close</v-icon>
            </button> -->
          </v-card-title>
          <!-- <v-card-text class="pt-2"
            >Te hemos asignado un turno automáticamente ¿deseas continuar? En
            {{ finalTextDerivate }} segundos tipificaremos y derivaremos con
            prioridad tu turno</v-card-text
          > -->
          <v-card-text class="pt-3 text-center" v-if="firstTurn"
            ><strong style="font-size: 1.3rem">
              Turno asignado: {{ handlerFirstTurn }}
            </strong></v-card-text
          >
          <v-card-text class="pt-3 pb-0" v-if="!firstTurn">
            <strong style="font-size: 1.1rem"
              >Se te esta asignando un turno automaticamente</strong
            >
          </v-card-text>
          <v-card-text class="pt-3 pb-0" v-if="firstTurn">
            <strong style="font-size: 1.1rem">Tiempo para tomar turno:</strong>
          </v-card-text>
          <div class="progress" :data-percentage="percent" v-if="firstTurn">
            <span class="progress-left">
              <span class="progress-bar"></span>
            </span>
            <span class="progress-right">
              <span class="progress-bar"></span>
            </span>
            <div class="progress-value">
              <span>
                {{ finalTextDerivate }}
              </span>
            </div>
          </div>
          <v-card-text class="pt-3"
            ><small
              >El turno volverá a la cola al finalizar el contador</small
            ></v-card-text
          >
          <v-card-actions class="m-0 p-0" style="padding: 0">
            <v-spacer></v-spacer>
            <!-- <v-btn
              color="green darken-1"
              text
              @click="showModalToCallAuto = false"
            >
              No
            </v-btn> -->
            <v-btn
              :disabled="!firstTurn"
              block
              @click="acceptTurnAutoMethod(true)"
              color="primary"
              class="m-2 p-10"
              style="border-radius: none; padding: 1.5rem"
              >TOMAR TURNO</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        persistent
        v-model="showSearchAllTurnsModel"
        max-width="450"
        class="p-0"
      >
        <div
          v-if="showSearchAllTurns && !showSearchAbstenTurns"
          style="padding: 1rem; background: white"
        >
          <v-card-title
            style="
              display: flex;
              justify-content: space-between;
              position: relative;
            "
          >
            <v-text-field
              v-model="searchSecondAllturns"
              label="Buscar:"
              class="p-0"
              style="padding: 0; margin-left: 2rem"
            ></v-text-field>
            <v-btn
              style="position: absolute; top: -12px; right: -1px"
              icon
              @click="closeCall"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <div style="max-height: 230px; overflow: auto">
            <template v-if="searchAllTurnsResults.length">
              <p
                v-for="(result, index) in searchAllTurnsResults"
                :key="index"
                style="cursor: pointer"
                @click="() => callSingularTurnById(result)"
              >
                {{ result.letter + result.number }}
              </p>
            </template>
            <p v-else>No hay resultados</p>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <v-btn @click="closeCall">Cerrar</v-btn>
          </div>
        </div>
        <div
          v-if="showSearchAbstenTurns && !showSearchAllTurns"
          style="
            padding: 1rem;
            background: white;
            max-height: 460px;
            overflow: auto;
          "
        >
          <v-card-title
            style="
              display: flex;
              justify-content: space-between;
              position: relative;
            "
          >
            <v-text-field
              v-model="searchSecondAbstenturns"
              label="Buscar:"
              class="p-0"
              style="padding: 0; margin-left: 2rem"
            ></v-text-field>
            <v-btn
              style="position: absolute; top: -12px; right: -1px"
              icon
              @click="closeCall"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <div style="max-height: 230px; overflow: auto">
            <template v-if="searchAbsentTurns.length">
              <p
                v-for="(result, index) in searchAbsentTurns"
                :key="index"
                style="cursor: pointer"
                @click="() => callSingularTurnById(result)"
              >
                {{ result.letter + result.number }}
              </p>
            </template>
            <p v-else>No hay resultados</p>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <v-btn @click="closeCall">Cerrar</v-btn>
          </div>
        </div>
      </v-dialog>
    </div>

    <!-- Recorder Alert -->
    <v-dialog
      v-model="showRecorderAlert"
      persistent
      max-width="500"
      class="p-0"
    >
      <v-card class="text-center p-0">
        <v-img :src="recorderScreen" height="350px"></v-img>

        <v-card-title
          style="
            display: flex;
            justify-content: space-between;
            position: relative;
          "
        >
          {{ messageRecorderAlert }}
        </v-card-title>

        <v-card-actions class="m-0 p-0" style="padding: 0">
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="green darken-1"
            text
            @click="showModalToCallAuto = false"
          >
            No
          </v-btn> -->
          <v-btn
            block
            @click="recordScreen(true)"
            color="primary"
            class="m-2 p-10"
            style="border-radius: none; padding: 1.5rem"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import moment from "moment";
import RecordRTC from "recordrtc";

var AWS = require("aws-sdk");

// import * as ebml from "ts-ebml";

// let stream, voiceStream, desktopStream;
let stream, desktopStream;

import recorderScreen from "@/silver/assets/img/recorder-screen.png";

export default {
  name: "Panel",
  components: {
    VideoCall: () => import("./Video"),
    GluedDetail: () => import("../components/gluedDetail"),
    FinishButtons: () => import("../components/finishButtons"),
    Formativity: () => import("../components/Formativitys"),
    CallDetailCustomer: () => import("../components/callDetailCustomer"),
    VuePerfectScrollbar: () => import("vue-perfect-scrollbar"),
    Loading: () => import("../components/Loading"),
    // LoadingPage: () => import("./Loading.vue"),
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
      seconds: 0,
      minutes: 0,
      totalCounterStates: null,
      loadingChangeState: false,
      loadingChangeStateTimer: null,
      showSearchAllTurnsModel: "",
      percent: 100,
      loadingAll: true,
      boxPlace: "",
      timerNumber: 15000,
      counterTimerPolling: 0,
      optionsSelectInputWaitingRoom: [],
      optionsSelectInputState: [],
      selectOptionWaitingRoom: "",
      selectOptionState: "",
      profile: null,
      snackbar: false,
      timer2: null,
      text: "",
      timerPolling: null,
      counterAttended: 0,
      counterMinutes: 0,
      newDateTimer: null,
      visibleSearch: false,
      // showPanelSeconds: "-",
      configs: {},
      search: "",
      visibleSecondSearch: false,
      searchSecond: "",
      // allTurns: ["A8", "B9", "T8", "Y63", "TODO"],
      // counter call
      showPanelSeconds: 0,
      showTotalStanByTime: 0,
      timerAtentionTime: null,
      secondsInactivity: 60,
      minutesInactivity: 19,
      finalTextInactivity: "00:00:00",
      counterInactivity: null,
      totalCounterAllInactivity: "00:00:00",

      // btns searching
      showAllTurns: false,
      showSearchAllTurns: false,
      searchSecondAllturns: "",
      showSearchAbstenTurns: false,
      searchSecondAbstenturns: "",
      showModalToCallAuto: false,
      finalTextDerivate: 0,
      // acceptTurnAuto: false,

      // video recorder
      stopped: false,
      shouldStop: false,
      downloadLink: {},
      mediaRecorder: null,
      recordedChunks: {},
      sendingToS3: false,

      // vide recorder preserve
      callDataTemp: {},
      generalRecordedChunks: [],

      acceptTurnAutoMethod: () => {},

      // db
      dbconnect: null,
      dbObject: null,
      intervalAuto: null,
      queryGroup: null,
      dbBlobsRecorder: null,
      dbBlobObject: null,

      // change state
      changeState: false,
      objectNewState: {},
      minutesInactivityAlmuerzo: 59,
      minutesInactivityBreak: 9,
      timerInactivityAll: null,

      // recorder
      mustRecord: false,

      // for change route
      changeRoute: false,

      initWork: false,
      dateLastChangedState: new Date(),

      // for recorder modal
      showRecorderAlert: false,
      messageRecorderAlert: "",
      resolveRecorder: null,
      recorderScreen,

      // auto call
      timeOutModalAutoCall: null,

      pollingActive: false,
      timeIntervalMemory: null,

      // count turns
      turns_attended: {},
      today: moment().format("YYYY-MM-DD"),

      countCheckRecorders: 0,

      recorderRTC: null,
      countChunks: 0,
      sizeChunks: 0,

      version: null,

      // if timeout try to send again
      trySendCounter: 0
    };
  },
  mounted() {
    // Import EML.js to add seeking on blob files
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/EBML.js");
    plugin.async = true;
    document.head.appendChild(plugin);

    // stop screen recorder if exist
    let stream = this.$root.globalStream;
    let tracks = stream?.getTracks();
    if (tracks) for (var i = 0; i < tracks.length; i++) tracks[i].stop();

    this.$root.countChangeToAbsent = 1;

    // enable change work station
    this.$root.enableChangeWorkStation = true;

    /* eslint-disable */

    // Activate Recorder
    setTimeout(async () => {

      this.fetchTurnsAttended();

      // check if exist environmentData.silver.recorders
      let recorders = await new Promise(resolve => {
        this.checkSilverProperty(resolve);
      })

      recorders = Array.isArray(recorders) ? recorders : Object.keys(recorders).map(r => parseInt(r));

      // create interval until to have branch id
      if(!this.selectedBranch.id){
        await  new Promise(resolve => {
          let i
          i = setInterval(() => {
            if(this.selectedBranch.id) {
              clearInterval(i)
              resolve();
            }
          }, 1000)
        })
      }

      if (recorders.includes(this.selectedBranch.id)) {

        this.mustRecord = true;
        this.changeMediaRecorder({ state: 'initial' });

        console.log('ACTIVATE RECORDER')

        // Initialize aws for save on s3 blobs
        AWS.config.region = 'us-east-1'; // 1. Enter your region
        const save_key = process.env.VUE_APP_AWS_POLLING_API_KEY;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: save_key // 2. Enter your identity pool
        });

        AWS.config.credentials.get(function (err) {
          if (err) alert(err);
        });

        await new Promise(resolve => {
          this.resolveRecorder = resolve;
          this.recordScreen();
        });

        // Check ¡IndexedDB API
        if (!('indexedDB' in window)) {
          alert("¡IndexedDB no es compatible!");
        }

        // window.indexedDB.deleteDatabase('recorders_local_db');
        // save blob files on device
        this.dbconnect = window.indexedDB.open('recorders_local_db', 1);

        this.dbconnect.onupgradeneeded = function (event) {
          console.log('Se creó la BD');

          var db = event.target.result;

          var objectStore = db.createObjectStore("videos", { keyPath: "id", autoIncrement: true });
          objectStore.createIndex("name", "name", { unique: false });
          objectStore.createIndex("turn_id", "turn_id", { unique: false });
          objectStore.createIndex("blob", "blob", { unique: false });
          objectStore.createIndex("branch_name", "branch_name", { unique: false });
          objectStore.createIndex("branch_id", "branch_id", { unique: false });
          objectStore.createIndex("turn", "turn", { unique: false });
          objectStore.createIndex("created", "created", { unique: false });
          objectStore.createIndex("sync", "sync", { unique: false });
        }

        this.dbconnect.onsuccess = env => {
          console.log('BD-Actualización exitosa');
          this.dbObject = env.target.result;
          this.videosSync();
        };

        // recorders to Blob files
        try {
          this.dbBlobsRecorder = window.indexedDB.open('blobs_local_db', 1);
        } catch (error) {
          console.log("ERROR: " + error);

          var item = {
            dni: this.callDataTemp?.customer?.dni, // client document
            workstation:
              this.selectedBranch?.id || this.branchesData.defaultBranch.id,
            user_id: this.loggedUser?.id,
            branch_id: this.selectedBranch?.id,
            turn: this.callDataTemp?.letter + this.callDataTemp?.number,
            created: moment(this.callDataTemp?.callTimeStamp)
              .utcOffset("-05:00")
              .unix(), // timestamp
          };
          // Error
          this.saveLog({
            'turn_code': this.callDataTemp?.code || 'N/R',
            'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + localStorage.getItem('current_code'),
            'status': "window.indexedDB failed: " + error + ' ' + this.version,
          });
        }

        this.dbBlobsRecorder.onupgradeneeded = function (event) {
          console.log('Se creó la Blob file BD');

          var db = event.target.result;
          var objectStoreFile = db.createObjectStore("webms", { keyPath: "id", autoIncrement: true });
          objectStoreFile.createIndex("name", "name", { unique: true });
          objectStoreFile.createIndex("blob", "blob", { unique: false });
        }

        this.dbBlobsRecorder.onsuccess = env => {
          console.log('BD Blob file-Actualización exitosa');
          this.dbBlobObject = env.target.result;
        };

        this.dbBlobsRecorder.onerror = env => {
          alert("Error al cargar la información de grabación. Parece que tu navegador esta desactualizado. Si el problema persiste por favor cierra completamente el navegador (con todas las pestañas) y vuelve a ingresar");
          console.error(env)
        };

        // Init all
        this.handlerAllData();

      } else {

        // Init all
        this.handlerAllData();

      }

    }, 1000);

    // change state to conected when the user in
    this.initWork = true;

    // version 
    this.version = process.env.PACKAGE_VERSION

    /* eslint-enable */
  },
  created() {
    this.changeVerticalSidebarDrawer(false);
  },
  destroyed() {
    // for (var i = 1; i < (this.timerPolling + 10); i++) {
    //   clearInterval(i);
    // }
    clearInterval(this.timer2);
    clearInterval(this.counterInactivity);
    clearInterval(this.timerAtentionTime);
    clearInterval(this.intervalAuto);
    clearInterval(this.loadingChangeStateTimer);
    this.restartCounterInactivityAll();
    this.pollingActive = false;
    // if (this.callData) {
    //   this.handleFinishCall(true);
    // }
  },
  watch: {
    selectOptionState(val) {
      if (this.loadingState) return;

      val = val.name || val;

      if (val != "Desconectado") {
        // init polling
        setTimeout(() => this.initPolling(), 1000);
      } else {
        // reset timer polling
        clearTimeout(this.timerPolling);
        this.pollingActive = false;
        const filteredResponse = this.handlerStateAgentToSelect(
          this.loggedUser
        )?.find((valResponse) => valResponse.name === val);

        this.updateStateAction({
          id: filteredResponse.id,
          name: filteredResponse.name,
        });
      }

      // open modal autocall
      clearTimeout(this.timeOutModalAutoCall);
    },
    selectedQueueState(val) {
      if (val) this.queryGroup = val;
    },
    possibleStatesOfflineTimeEvaluation(val) {
      if (val) {
        this.seconds = val.seconds;
        this.minutes = val.minutes;
        this.restartCounterInactivityAll(val);
        this.timerInactivityAll = setInterval(() => {
          this.seconds--;
          if (this.seconds === 0 && this.minutes > 0) {
            this.seconds = 59;
            this.minutes--;
          }
          this.totalCounterStates = `00:${this.minutes >= 10 ? "" : "0"}${this.minutes
            }:${this.seconds >= 10 ? "" : "0"}${this.seconds}`;
          this.totalCounterAllInactivity = `00:${this.minutes >= 10 ? "" : "0"
            }${this.minutes}:${this.seconds >= 10 ? "" : "0"}${this.seconds}`;
          if (this.minutes === 0 && this.seconds === 0) {
            this.restartCounterInactivityAll();
            const filteredResponse = this.handlerStateAgentToSelect(
              this.loggedUser
            )?.find((valResponse) => valResponse.name === "Desconectado");
            this.selectOptionState = filteredResponse;
          }
        }, 1000);
      }
    },
    "$route.params.workStationId"() {
      // change workstation
      clearTimeout(this.timeOutModalAutoCall);
      this.changeVerticalSidebarDrawer(false);
      this.setErrorForce(null);
      this.getAllData();
      setTimeout(() => {
        const filteredResponse = this.handlerStateAgentToSelect(
          this.loggedUser
        )?.find((valResponse) => valResponse.name === "Conectado - Disponible");

        this.updateStateAction({
          id: filteredResponse.id,
          name: filteredResponse.name,
        }).then(() => {
          this.selectOptionState = filteredResponse;
        });
      }, 1000);
    },
    "loggedUser.worker"(val_w) {
      if (!val_w) return;
      this.boxPlace = val_w.name;

      // update state automatically
      if (
        val_w.currentState.name != "Conectado - Disponible" &&
        val_w.currentState.name != "Conectado - En atención" &&
        !this.changeState &&
        this.initWork
      ) {
        this.initWork = false;

        if (this.selectOptionState.name == "Conectado - Disponible") {
          this.selectOptionState = this.selectOptionState
            ? this.selectOptionState
            : val_w.currentState;
        } else {
          var conected_state = val_w.workerProfile.possibleStates?.filter(
            (state) => state.name == "Conectado - Disponible"
          )[0];
          if (conected_state) {
            this.updateStateAction({
              id: conected_state.id,
              name: conected_state.name,
            }).then(() => {
              this.selectOptionState = conected_state;
            });
          } else {
            if (val_w.workerProfile.initialState) {
              conected_state = {
                id: val_w.workerProfile.initialState.id,
              };
              this.updateStateAction({
                id: conected_state.id,
                name: conected_state.name,
              }).then(() => {
                this.selectOptionState = conected_state;
              });
            }
          }
        }
      }

      this.selectOptionWaitingRoom = val_w.waitingRoom.name;
      this.optionsSelectInputWaitingRoom = [
        {
          text: val_w.waitingRoom.name,
          value: val_w.waitingRoom.name,
        },
      ];
      this.profile = val_w.workerProfile.name;
      this.optionsSelectInputState = this.handlerStateAgentToSelect(val_w);
      this.configs = val_w.configs;

      if (!this.selectOptionState) {
        this.selectOptionState = val_w.currentState;
        this.setAsesorState(this.selectOptionState.name);
      }

      // if (this.$root.autoCall) {
      //   if (this.$root.acceptTurnAuto) {
      //     this.selectOptionState = val_w.currentState;
      //     this.setAsesorState(val_w.currentState?.name);
      //   }
      // } else {
      //   this.selectOptionState = val_w.currentState;
      //   this.setAsesorState(val_w.currentState?.name);
      // }
    },
    error(val) {
      if (!val) return;
      this.snackbar = true;
      this.text = val;
    },
    errorPolling(e) {
      if (!e) return;
      console.error(e);
      if (e == 400) return location.reload();
      if (e == 401) {
        this.signOut();
        console.log("Cerrar sesión: " + e);
        this.$router.push({ path: "/sessions/login" });
      }
    },
    counterTimerPolling() {
      let now = moment();
      if (this.callData) {
        let init_now;
        if (localStorage.getItem("attention_time" + this.callData?.id)) {
          init_now = localStorage.getItem("attention_time" + this.callData?.id);
        } else {
          init_now = moment();
          localStorage.setItem("attention_time" + this.callData?.id, init_now);
        }
        var initTimeStamp = moment(String(init_now));
        this.showPanelSeconds = now.diff(initTimeStamp, "seconds");
      } else {
        this.showPanelSeconds = now.diff(moment(), "seconds");
      }
    },

    // Auto Call Detect
    async pollingData(polling) {
      if (
        polling &&
        this.config.automaticCallEnabled &&
        !this.callData &&
        polling.callableQueueGroupsStandBy > 0 &&
        !this.showModalToCallAuto &&
        this.$root.enableChangeWorkStation
      ) {
        if (this.changeRoute) return (this.changeRoute = false);

        this.timeOutModalAutoCall = setTimeout(() => {
          // call
          if (
            (typeof this.selectOptionState == "object" &&
              this.selectOptionState.name == "Conectado - Disponible") ||
            (typeof this.selectOptionState == "string" &&
              this.selectOptionState == "Conectado - Disponible")
          ) {
            // disable change work station
            this.$root.enableChangeWorkStation = false;
            this.handleCallNextTurn(true);
          }
        }, this.config.automaticCallDelay * 1000);
      }

      var now = new Date();
      var seconds =
        (now.getTime() - this.dateLastChangedState.getTime()) / 1000;
      if (
        polling &&
        (polling.currentState.name == "Ausente" ||
          polling.currentState.name == "Desconectado") &&
        seconds > 60
      ) {
        if (this.selectOptionState) {
          if (
            this.selectOptionState.name != "Ausente" &&
            this.selectOptionState.name != "Desconectado"
          ) {
            this.selectOptionState = polling.currentState;
            this.setAsesorState(this.selectOptionState.name);
          }
        } else {
          this.selectOptionState = polling.currentState;
          if (polling.currentState.name == "Desconectado") {
            // reset timer polling
            clearTimeout(this.timerPolling);
            this.pollingActive = false;
          }
        }
      }

      // check memory
      let memory = window?.performance?.memory;

      if (memory.usedJSHeapSize >= memory.jsHeapSizeLimit) {
        // Send data to tracking
        this.sendtracking({
          uuid: window.person.id,
          accion: "EMERGENCY-memory-status",
          debmedia_turn_code: this.callData.code,
          url_origen: window.location.href,
          origen: "silver",
          mensajes: JSON.stringify({
            jsHeapSizeLimit: memory.jsHeapSizeLimit,
            totalJSHeapSize: memory.totalJSHeapSize,
            usedJSHeapSize: memory.usedJSHeapSize,
          }),
        });
      }
    },

    async callData(value) {
      this.fetchTurnsAttended();

      console.log("VIDEO LLAMADA:" + value?.id);
      console.log(value);

      // if(this.$root.acceptTurnAuto) return false;

      if (value && value.id) {
        // standby user
        this.showTotalStanByTime = value.totalStanByTime;

        if (value.extraFields?.Videollamada) {
          setTimeout(() => {
            this.$root.acceptTurnAuto = true;
            if (this.$refs.detailCustomer && this.config.turnNotification)
              this.$refs.detailCustomer.open();
            this.openVideo();
          }, 1000);
        }

        this.optionsSelectInputState = this.handlerStateAgentToSelect(
          this.loggedUser
        );

        // conservate
        this.callDataTemp = value;

        // init new recorder
        this.generalRecordedChunks = [];

        // let mom = moment().utc();
        // timedate_rec = mom.format("DDMMYYYY");
        // hour_rec = mom.format("HH");
        // ---

        if (!this.recordedChunks[value.id]) {
          this.recordedChunks[value.id] = [];
        }

        if (this.recorderRTC) {
          console.log("this.recorderRTC Status");
          console.log(this.recorderRTC.state);
          this.changeMediaRecorder({ state: "recording" });
          this.recorderRTC.startRecording();
        }

        this.timerAtentionTime = setInterval(() => {
          this.counterTimerPolling += 1;
        }, 1000);
      } else {
        this.restartTimer();
        // if (this.mediaRecorder && this.mediaRecorder.state == "recording") {
        //   this.mediaRecorder.stop();
        // }

        this.videoChange(false);
        this.$root.enableChangeWorkStation = true;
        clearInterval(this.timeIntervalMemory);
      }
    },
    $route() {
      this.$root.countChangeToAbsent = 1;
      this.changeRoute = true;
      clearInterval(this.timerInactivityAll);
    },
    // "getThemeMode.verticalSidebarDrawer"(val) {
    //   this.changePolling(val);
    // },
    showAllTurns(val) {
      if (val) {
        // reset timer polling
        clearTimeout(this.timerPolling);
        this.pollingActive = false;
        // --
        clearInterval(this.timer2);
      } else this.initPolling();
    },
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      selectedBranch: (state) => state.branchesSilver.selectedBranch,
      branchesData: (state) => state.branchesSilver.branchesData,
      loggedUser: (state) => state.loggedUserSilver.loggedUserData,
      statsWorker: (state) => state.loggedUserSilver.statsWorker,
      debqMobile_turn: (state) => state.loggedUserSilver.debqMobile_turn,
      allTurns: (state) => state.loggedUserSilver.allTurns,
      absentTurns: (state) => state.loggedUserSilver.absentTurns,
      errorForce: (state) => state.loggedUserSilver.errorForce,
      callData: (state) => state.loggedUserSilver.callData,
      callDataTempFrom: (state) => state.loggedUserSilver.callDataTemp,
      updateStateValue: (state) => state.loggedUserSilver.updateStateValue,
      error: (state) => state.loggedUserSilver.error,
      errorPolling: (state) => state.pollingWorkplaceSilver.errorPolling,
      pollingData: (state) => state.pollingWorkplaceSilver.polling,
      pollingDataLoading: (state) => state.pollingWorkplaceSilver.loadingP,
      videoCallSilverState: (state) => state.videoCallSilver.videoData,
      derivableQueueGroups: (state) =>
        state.loggedUserSilver.derivableQueueGroups,
      turnsQueue: (state) => state.loggedUserSilver.turnsQueue,
      turnDataToCall: (state) => state.loggedUserSilver.turnDataToCall,
      config: (state) => state.loggedUserSilver.loggedUserData.worker?.configs,
      derivableCancelData: (state) => state.derivateSilver,
      AllCall: (state) => state.loggedUserSilver.loading,
      firstTurn: (state) => state.loggedUserSilver.firstTurn,
      selectedQueueState: (state) => state.loggedUserSilver.selectedQueueState,
      profileData: (state) => state.profileSilver.profileData,
      environmentData: (state) => state.environmentSilver.environment,
      loadingState: (state) => state.loggedUserSilver.loadingState,
      logRecorders: (state) => state.loggedUserSilver.logRecorders,
    }),
    showFormativity() {
      return this.callData
        ? true
        : this.selectOptionState === "Conectado - Disponible" ||
        this.selectOptionState?.name === "Conectado - Disponible" ||
        this.selectOptionState?.name === "Conectado - En atención" ||
        this.selectOptionState === "Conectado - En atención";
    },
    datastatsWorker() {
      return {
        ticket: this.statsWorker?.attentionTickets || 0,
        timeAtt: this.statsWorker?.attentionTime || 0,
      };
    },
    userData() {
      return `${this.profileData.user?.firstName || ""} ${this.profileData.user?.lastName || ""
        }`;
    },
    emitedHour() {
      return this.callData.initTimeStamp
        ? moment(this.callData.initTimeStamp).format("hh:mm a")
        : "";
    },
    possibleStatesOfflineTimeEvaluation() {
      this.selectOptionState;
      return this.possibleStatesOfflineTime.length
        ? this.possibleStatesOfflineTime.find(
          (state) =>
            state === this.selectOptionState ||
            state?.name === this.selectOptionState?.name ||
            state?.name === this.selectOptionState
        )
        : undefined;
    },
    possibleStatesOfflineTime() {
      const filteredSearch =
        this.loggedUser.worker?.workerProfile.possibleStates?.filter(
          (possibleState) => +possibleState.offlineTimeInMinutes
        );
      return (
        filteredSearch?.map((item) => {
          item.totalCounter = `00:00:00`;
          let resultReal = item.offlineTimeInMinutes - 1;
          item.stopTimer = () => {
            clearInterval(this.timerInactivityAll);
            item.totalCounter = `00:${resultReal > 10 ? resultReal : resultReal
              }:00`;
          };
          item.minutes = JSON.parse(JSON.stringify(resultReal));
          item.seconds = 59;
          item.hours = 0;
          return item;
        }) || []
      );
    },
    handlerFirstTurn() {
      return this.firstTurn.letter + this.firstTurn.number;
    },
    firstTurnTaked() {
      return (() => {
        return this.queueGroups[0].queue.turns[0] || "";
      })();
    },
    searchResults() {
      return this.queueGroups.filter((turn) => {
        return (
          turn.queue.standBy &&
          turn?.name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    searchSecondResults() {
      return this.turnsQueue.filter((turn) => {
        const resultChange = String(turn.letter + turn.number);
        return resultChange
          .toLowerCase()
          .includes(this.searchSecond.toLowerCase());
      });
    },
    searchAllTurnsResults() {
      return this.allTurns.filter((turn) => {
        const resultChange = String(turn.letter + turn.number);
        return resultChange
          .toLowerCase()
          .includes(this.searchSecondAllturns.toLowerCase());
      });
    },
    searchAbsentTurns() {
      return this.absentTurns.filter((turn) => {
        const resultChange = String(turn.letter + turn.number);
        return resultChange
          .toLowerCase()
          .includes(this.searchSecondAbstenturns.toLowerCase());
      });
    },
    queueGroups() {
      return this.pollingData?.callableQueueGroups?.map((queue) => {
        if (this.loggedUser.worker.callableQueueGroups) {
          return {
            ...this.loggedUser.worker.callableQueueGroups.find(
              (dq) => dq.id === queue.id
            ),
            queue,
          };
        } else {
          return {
            ...this.loggedUser.worker.orderedCallableQueueGroups.find(
              (dq) => dq.id === queue.id
            ),
            queue,
          };
        }
      });
    },
    atended() {
      return this.callData
        ? `${this.callData?.letter} ${this.callData?.number}`
        : "-";
    },
  },
  methods: {
    ...mapMutations(["setErrorForce"]),
    ...mapActions([
      "loggedUserGetAction",
      "getBeforeCall",
      "callTurnAction",
      "callTurnActionById",
      "changeMediaRecorder",
      "finishTurnAction",
      "getWorkerStatsAction",
      "pollingWorkplaceGetAction",
      "signOut",
      "revokeTurnAction",
      "updateStateAction",
      "loggedUserSilver/clearAllData",
      "videoChange",
      "handlerGetAllTurns",
      "handlerGetAbstenTurns",
      "handlerGetQueueTurns",
      "handlerGetTurnDataToCall",
      "cancelCall",
      "handlerRequeueAction",
      "changeVerticalSidebarDrawer",
      "getCompanyBranchesInfo",
      "fetchUuidTypesByCompany",
      "absentAction",
      "toCancelDerivate",
      "handleResetDataDerivation",
      "newAssignAutomaticallyTurn",
      "reCallTurnAction",
      "handlerDerivableQueueGroups",
      "resetCallData",
      "setAsesorState",
      "checkToken",
      "sendtracking",
      "setCallData",
      "saveLog",
      "fetchRecorder"
    ]),
    restartCounterInactivity() {
      clearInterval(this.counterInactivity);
      this.secondsInactivity = 60;
      this.minutesInactivity = 19;
      this.minutesInactivityBreak = 9;
      this.minutesInactivityAlmuerzo = 59;
      this.finalTextInactivity = "00:00:00";
    },
    restartCounterInactivityAll(val) {
      this.totalCounterAllInactivity = "00:00:00";
      clearInterval(this.timerInactivityAll);
      if (val) {
        val.seconds = 60;
        val.minutes = JSON.parse(JSON.stringify(val.offlineTimeInMinutes - 1));
        val.totalCounter = "00:00:00";
      }
    },

    async attendCall(auto) {
      if (auto) {
        this.$root.autoCall = auto;
        const autoCallENV = JSON.parse(process.env.VUE_APP_AUTO_CALL);
        if (
          autoCallENV &&
          autoCallENV[
            typeof this.selectedBranch === "object"
              ? this.selectedBranch.label
              : this.selectedBranch
          ]?.confirmAutoCall &&
          !this.$root.acceptTurnAuto
        ) {
          if (this.firstTurn) {
            // show modal
            this.showModalToCallAuto = true;
            this.$nextTick();

            document.getElementById("attended").play();

            // stop polling while user accept call
            clearInterval(this.timerPolling);
            clearInterval(this.timer2);
            this.pollingActive = false;

            // wait agent response from autocall modal
            this.$root.acceptTurnAuto = await this.requetUsertAttendAutoCall();

            document.getElementById("attended").pause();
            document.getElementById("attended").currentTime = 0;
            this.showModalToCallAuto = false;

            //  try again counter
            if (!this.$root.acceptTurnAuto) {
              if (this.$root.countChangeToAbsent) {
                this.$root.countChangeToAbsent++;
              } else {
                this.$root.countChangeToAbsent = 1;
              }
              // restart polling if user not accept call
              this.initPolling();
              return this.handlerCancelCall(true); // with autocall
            } else {
              const filterConnected = this.optionsSelectInputState.find(
                (valResponse) => valResponse.name === "Conectado - En atención"
              );
              this.selectOptionState = filterConnected;
              this.setCallData(this.callDataTempFrom);
              this.setAsesorState(this.selectOptionState.name);
            }
          }
        } else this.$root.acceptTurnAuto = true;

        const timerIf = setTimeout(() => {
          if (this.config && this.config.turnNotification) {
            this.newAssignAutomaticallyTurn(this.atended);
          }
          clearTimeout(timerIf);
        }, 2000);
      }
    },

    // reset all data after finish turn
    async resetCall(state = "Conectado - Disponible") {
      // reset callData
      this.resetCallData();

      // change state after end call
      if (this.changeState) {
        this.restartTimer();

        this.selectOptionState = this.objectNewState;
        this.setAsesorState(state);
        await this.updateStateAction({
          id: this.objectNewState.id,
          name: this.objectNewState.name,
        });

        this.objectNewState = {};
        this.changeState = false;
      } else {
        if (this.updateStateValue != state) {
          const filterConnected = this.optionsSelectInputState.find(
            (valResponse) => valResponse.name === state
          );
          this.selectOptionState = filterConnected;
          this.setAsesorState(state);
        }
      }

      // enable change work station
      this.$root.enableChangeWorkStation = true;
      this.videoChange(false);
      this.loadingAll = false;
    },

    async getAllData(force) {
      this.loadingAll = true;

      await this.loggedUserGetAction({
        branchId: this.selectedBranch.id,
        workerId: parseFloat(this.$route.params.workStationId),
        force: force ? true : false,
      });
      await this.getWorkerStatsAction(
        parseFloat(this.$route.params.workStationId)
      );

      try {
        await this.fetchUuidTypesByCompany();
      } catch (error) {
        console.log("Error: " + error);
      }

      try {
        await this.handlerDerivableQueueGroups();
      } catch (error) {
        console.log("Error: " + error);
      }

      this.loadingAll = false;
    },
    async handlerCallSingularTurn(turn) {
      await this.handlerGetQueueTurns(turn);
      this.visibleSecondSearch = true;
    },
    async callSingularTurnById(turn) {
      await this.handlerGetTurnDataToCall(turn.id);
      this.closeCall();
      this.handleResetDataDerivation();
      this.callTurnActionById({
        "queueGroup.id": turn.standAloneGroup?.id,
        "turn.id": turn.id,
        "waitingRoom.id": turn.waitingRoom.id,
      });

      // Reset Data
      this.attendCall(false);
    },
    handlerStateAgentToSelect(val) {
      if (val.worker) {
        return val.worker?.workerProfile.possibleStates?.map((state) => ({
          name: state.name,
          value: state.name,
          id: state.id,
        }));
      } else {
        return val.workerProfile?.possibleStates?.map((state) => ({
          name: state.name,
          value: state.name,
          id: state.id,
        }));
      }
    },
    closeCall() {
      this.showSearchAllTurnsModel = false;
      this.visibleSearch = false;
      this.visibleSecondSearch = false;
      this.search = "";
      this.searchSecond = "";
      this.showAllTurns = false;
      this.showSearchAllTurns = false;
      this.showSearchAbstenTurns = false;
      this.$root.acceptTurnAuto = false;
    },
    handleVisibleSearch() {
      this.visibleSearch = true;
    },
    handleVisibleSearchAll() {
      this.showAllTurns = true;
      this.showSearchAllTurns = false;
    },
    async handleSearchAllturns() {
      await this.handlerGetAllTurns();
      this.showSearchAllTurns = true;
      this.showSearchAllTurnsModel = true;
    },
    async handleSearchAbsent() {
      await this.handlerGetAbstenTurns();
      this.showSearchAbstenTurns = true;
      this.showSearchAllTurnsModel = true;
    },
    // next all or autocall
    async handleCallNextTurn(auto = false) {
      clearTimeout(this.timeOutModalAutoCall);
      if (!auto && !this.$root.enableChangeWorkStation) return;

      var params = {};
      if (this.loggedUser.worker.callableQueueGroups && this.loggedUser.worker.callableQueueGroups.length > 0)
        params["queueGroup.id"] =
          this.loggedUser.worker.callableQueueGroups[0].queues[0].id;
      if (this.loggedUser.worker.orderedCallableQueueGroups && this.loggedUser.worker.orderedCallableQueueGroups.length > 0)
        params["queueGroup.id"] =
          this.loggedUser.worker.orderedCallableQueueGroups[0].queues[0].id;
      if (auto) params.automaticCall = true;
      params["waitingRoom.id"] = this.loggedUser.worker.waitingRoom?.id;
      let res = await this.callTurnAction(params);
      if (res == "No hay personas para llamar." || !res) {
        this.snackbar = true;
        this.text = res;
        // enable change work station
        this.$root.enableChangeWorkStation = true;
        return;
      } else {
        this.handleResetDataDerivation();

        // Handle call data
        this.attendCall(auto);

        // Save log recorders
        var item = {
          dni: this.callData?.customer?.dni, // client document
          workstation:
            this.selectedBranch.id || this.branchesData.defaultBranch.id,
          user_id: this.loggedUser.id,
          branch_id: this.selectedBranch.id,
          turn: this.callData.letter + this.callData.number,
          created: moment(this.callData.callTimeStamp)
            .utcOffset("-05:00")
            .unix(), // timestamp
        };
        this.saveLog({
          'turn_code': this.callData?.code || 'N/R',
          'user': item.user_id,
          'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + this.callData?.code,
          'status': 'Start turn ' + this.version,
        });
      }
    },
    async handlerCancelCall(auto = false) {
      await this.cancelCall();

      if (
        this.$root.countChangeToAbsent >
        this.environmentData.silver?.autocall.attempts
      ) {
        this.$root.countChangeToAbsent = 0;
        return this.resetCall("Ausente");
      }

      if (!auto) {
        this.restartTimer();
        this.$root.autoCall = false;
        this.$root.acceptTurnAuto = false;

        // enable change work station
        this.$root.enableChangeWorkStation = true;
      }

      this.resetCall();
    },
    // send call to end
    async handlerRequeue() {
      await this.handlerRequeueAction();

      this.restartTimer();
      this.$root.autoCall = false;

      // llamamos los datos de estado del cliente desde el api
      this.$root.acceptTurnAuto = false;
      this.$root.countChangeToAbsent = 1;

      // Reset Data
      this.resetCall();
    },
    async handlerCallTurn() {
      const callableGroup = this.loggedUser.worker.callableQueueGroups ? this.loggedUser.worker.callableQueueGroups.find(
        (callG) => callG.queues.find((queue) => queue.id)
      ) : this.loggedUser.worker.orderedCallableQueueGroups.find(
        (callG) => callG.queues.find((queue) => queue.id)
      );
      this.callTurnAction({
        queueGroupId: callableGroup.id,
      });
    },
    async handleFinishCall(evaluator, service_id = null) {
      if (this.recorderRTC) {
        this.recorderRTC.stopRecording(() => {

          // Change recorder icon
          setTimeout(() => {
            this.changeMediaRecorder({ state: "inactive" });
          }, 3000);
          this.countChunks = 0;
          this.sizeChunks = 0;

          // let blob = this.recorderRTC.getBlob();
          let blobFull;

          // merge with previus blobs
          // search blobs
          var transaction;
          if(this.dbBlobObject){
            try{
              transaction = this.dbBlobObject.transaction(["webms"], "readwrite");
            } catch (error) {
              console.error(error)
            }
          }
          if (transaction) {
            var objectStore = transaction.objectStore("webms");
            var requestStop = objectStore.getAll();

            // blob object  stored no exist maybe
            requestStop.onerror = function (event) {
              console.log("WEBM LOCAL NO EXIST");
              console.log(event);
              this.sendDirectAndFinish(evaluator, service_id, this.recorderRTC.getBlob());
            };

            requestStop.onsuccess = async () => {
              // Do something with the request.result!
              let client_data = this.callDataTemp;
              let blobs = requestStop.result;
              console.log("full blobs:", blobs)
              if (blobs.length < 1) return console.log("No blobs stored");

              // controller
              let errors = false

              console.log("client code:", client_data.code)

              // filter videos for code of turn
              blobs = blobs.filter(b => b.name.includes(client_data.code))

              // add videos of webm to recorders
              for (let index = 0; index < blobs.length; index++) {
                blobFull = blobs[index];

                // override last blob save of current_code with current blob in recorder RTC
                if(blobFull.name.includes(localStorage.getItem('current_code'))) {
                  blobFull.blob = this.recorderRTC.getBlob();
                }
                
                try {
                  await new Promise((resolve) => {

                    // to fix video seeking issues
                    this.getSeekableBlob(blobFull.blob, async (seekableBlob) => {

                      // turn_code + number code
                      let _turncode = blobFull.name.split("_").slice(-2).join("_")

                      // create file of blob
                      const myFile = new File([seekableBlob], client_data.id + ".mp4", { type: "video/mp4", });
  
                      // Save log recorders
                      var item = {
                        dni: client_data.customer?.dni, // client document
                        workstation: this.selectedBranch.id || this.branchesData.defaultBranch.id,
                        user_id: this.loggedUser.id,
                        branch_id: this.selectedBranch.id,
                        turn: client_data.letter + client_data.number,
                        created: moment(client_data.callTimeStamp).utcOffset("-05:00").unix(), // timestamp
                      };
                      this.saveLog({
                        'turn_code': client_data.code || 'N/R',
                        'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + _turncode,
                        'status': 'Successfully get seeks from video (handleFinishCall) ' + this.version,
                      });
  
                      // before send the file to s3, we save it on the local systemm
                      var transactionV = this.dbObject.transaction(["videos"], "readwrite");
  
                      // SAVE ON LOCAL
                      var objectStore = transactionV.objectStore("videos");
                      item = {
                        name: _turncode,
                        dni: client_data.customer?.dni, // client document
                        workstation: this.selectedBranch.id || this.branchesData.defaultBranch.id,
                        user_id: this.loggedUser.id,
                        blob: myFile,
                        turn_id: client_data.id + '_' + _turncode,
                        branch_name: this.selectedBranch.label,
                        branch_id: this.selectedBranch.id,
                        turn: client_data.letter + client_data.number,
                        created: moment(client_data.callTimeStamp).utcOffset("-05:00").unix(), // timestamp
                        sync: false,
                      };
                      var requestSaved = objectStore.add(item);
                      requestSaved.onsuccess = () => { };
                      // When local storage is done, we send the file to S3
                      transactionV.oncomplete = async () => {
                        console.log("saved video in recorders");
                        // delete blob saved of webms
                        var objectBlobStoreR = this.dbBlobObject.transaction(["webms"], "readwrite");
                        objectBlobStoreR.objectStore("webms").delete(blobFull.id);
                        resolve()
                      };
  
                      transactionV.onerror = (e) => {
                        console.error(e)
                        errors = true
                        alert("Hubo un fallo en el envio de reportes: " + e);
  
                        // Save log recorders
                        var item = {
                          dni: client_data.customer?.dni, // client document
                          workstation: this.selectedBranch.id || this.branchesData.defaultBranch.id,
                          user_id: this.loggedUser.id,
                          branch_id: this.selectedBranch.id,
                          turn: client_data.letter + client_data.number,
                          created: moment(client_data.callTimeStamp).utcOffset("-05:00").unix(), // timestamp
                        };
                        this.saveLog({
                          'turn_code': client_data.code || 'N/R',
                          'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + _turncode,
                          'status': 'Error SAVE ON LOCAL (handleFinishCall) ' + this.version,
                        });
  
                        this.sendDirectAndFinish(evaluator, service_id, blobFull.blob);
                        this.finishCallData(evaluator, service_id);
                        resolve()
                      };
                    });
                  })
                } catch (error) {
                  errors = true;
                  alert(error)
                  this.sendDirectAndFinish(evaluator, service_id, blobFull.blob);
                }
              }

              if(!errors) {
                console.log("all videos ok init sync")
                // Send to S3
                this.videosSync();
                this.finishCallData(evaluator, service_id);
                // Delete webms
                var localBlobsDB = this.dbBlobObject.transaction(["webms"], "readwrite");
                var localBlobs = localBlobsDB.objectStore("webms");
                var webmsLocal = localBlobs.getAll();
                // blob object  stored no exist maybe
                webmsLocal.onerror = function (event) {
                  console.log("WEBMS LOCAL NO EXIST TO REMOVED");
                  console.log(event);
                };
                webmsLocal.onsuccess = async () => {
                  // Do something with the request.result!
                  let webmsLocalR = webmsLocal.result;
                  console.log("BLOBS Exists to remove")
                  console.log(webmsLocalR)
                  console.log("this.callData?.code")
                  console.log(client_data.code)
                  console.log("BLOBS Exists to remove Filtered")
                  console.log(webmsLocalR)
                  webmsLocalR.map(l=>localBlobs.delete(l.id));
                }
              }
            }
          } else {

            // Do something with the request.result!
            let blob = this.recorderRTC.getBlob();

            // to fix video seeking issues
            let client_data = this.callDataTemp;
            this.getSeekableBlob(blob, async (seekableBlob) => {

              // Save log recorders
              var item = {
                dni: client_data.customer?.dni, // client document
                workstation:
                  this.selectedBranch.id || this.branchesData.defaultBranch.id,
                user_id: this.loggedUser.id,
                branch_id: this.selectedBranch.id,
                turn: client_data.letter + client_data.number,
                created: moment(client_data.callTimeStamp).utcOffset("-05:00").unix(), // timestamp
              };
              this.saveLog({
                'turn_code': this.callData?.code || 'N/R',
                'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + localStorage.getItem('current_code'),
                'status': 'Success seeks without this.dbBlobObject (handleFinishCall) ' + this.version,
              });

              // file to upload
              const myFile = new File([seekableBlob], client_data.id + ".mp4", { type: "video/mp4", });

              if (this.dbObject) {

                // before send the file to s3, we save it on the local systemm
                var transaction = this.dbObject.transaction(["videos"], "readwrite");

                // SAVE ON LOCAL
                var objectStore = transaction.objectStore("videos");
                item = {
                  name: client_data.letter + client_data.number + "_" + client_data.id + '_' + localStorage.getItem('current_code'),
                  dni: client_data.customer?.dni, // client document
                  workstation:
                    this.selectedBranch.id || this.branchesData.defaultBranch.id,
                  user_id: this.loggedUser.id,
                  blob: myFile,
                  turn_id: client_data.id + '_' + localStorage.getItem('current_code'),
                  branch_name: this.selectedBranch.label,
                  branch_id: this.selectedBranch.id,
                  turn: client_data.letter + client_data.number,
                  created: moment(client_data.callTimeStamp).utcOffset("-05:00").unix(), // timestamp
                  sync: false,
                };
                var requestSaved = objectStore.add(item);
                requestSaved.onsuccess = () => { };

                // When local storage is done, we send the file to S3
                transaction.oncomplete = async () => {
                  console.log("All done!");

                  // Send to S3
                  this.videosSync();
                  this.finishCallData(evaluator, service_id);
                };

                transaction.onerror = async () => {
                  alert("Hubo un fallo en el envio de reportes");
                  this.sendDirectAndFinish(evaluator, service_id, myFile);
                };

              } else {

                this.sendDirectAndFinish(evaluator, service_id, myFile);
              }

            });
          }

        });
      } else {
        this.finishCallData(evaluator, service_id);
      }
    },

    async finishCallData(evaluator, service_id) {
      this.counterMinutes = 0;
      this.counterAttended = 0;
      this.$root.acceptTurnAuto = false;
      this.$root.countChangeToAbsent = 1;
      this.loadingAll = true;

      // enable change work station
      this.$root.enableChangeWorkStation = true;

      this.videoChange(false);

      if (evaluator === "absent") await this.absentAction();
      else if (!evaluator) await this.finishTurnAction(service_id);
      else await this.revokeTurnAction();

      try {
        // Save log recorders
        var item = {
          dni: this.callData?.customer?.dni, // client document
          workstation:
            this.selectedBranch.id || this.branchesData.defaultBranch.id,
          user_id: this.loggedUser.id,
          branch_id: this.selectedBranch.id,
          turn: this.callData?.letter + this.callData?.number,
          created: moment(this.callData?.callTimeStamp)
            .utcOffset("-05:00")
            .unix(), // timestamp
        };
        this.saveLog({
          'turn_code': this.callData?.code || 'N/R',
          'user': item.user_id,
          'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + this.callData?.code,
          'status': 'Finish turn ' + this.version,
        });
      } catch (error) {
        console.error(error)
      }

      this.resetCallData();
      if (this.$refs.videoCall) {
        this.$refs.videoCall.closeVideoCall();
      }

      this.restartTimer();
      this.$root.autoCall = false;

      // Reset Data
      this.resetCall();
    },

    finishDerivate() {
      this.counterMinutes = 0;
      this.counterAttended = 0;
      this.$root.acceptTurnAuto = false;
      this.$root.countChangeToAbsent = 1;
      this.loadingAll = true;

      // enable change work station
      this.$root.enableChangeWorkStation = true;

      this.videoChange(false);
      // this.changeVerticalSidebarDrawer(true);

      this.resetCallData();
      if (this.$refs.videoCall) {
        this.$refs.videoCall.closeVideoCall();
      }

      this.restartTimer();
      this.$root.autoCall = false;

      // Reset Data
      this.resetCall();
    },
    restartTimer() {
      // for (var i = 1; i < (this.timerPolling + 10); i++) {
      //   clearInterval(i);
      // }
      clearInterval(this.timer2);
      clearInterval(this.timerAtentionTime);
      this.counterTimerPolling = 0;
      // this.pollingActive = false;
      // this.$root.acceptTurnAuto = false;
    },
    async handleCancelDerivate() {
      this.toCancelDerivate(this.derivableCancelData.dataDerivaion);
      this.handleResetDataDerivation();
      this.setErrorForce(null);
      this.getAllData();
      this.videoChange(true);
    },

    async recordScreen() {
      this.showRecorderAlert = false;

      // check reset navigator
      let inital = true;

      try {

        // stream
        let stream = await this.captureAudioPlusScreen();

        // Rercording....
        var options = {
          type: "video",
          mimeType: "video/webm;codecs=h264",
          disableLogs: false,
          getNativeBlob: true, // enable it for longer recordings
          timeSlice: 10000,
          videoBitsPerSecond: 1800000,
          frameInterval: 25,
          // used by WebAssemblyRecorder
          frameRate: 25,
          ondataavailable: async (e) => {
            this.countChunks++;
            this.sizeChunks = this.sizeChunks + e.size;

            // Chek size from 3 blobs
            if (this.countChunks == 3) {
              // minimal size
              console.log("Chunk size (3): " + this.sizeChunks);
              if (this.sizeChunks < 80000) {
                // Send data to tracking
                await this.sendtracking({
                  uuid: window.person.id,
                  accion: "recording - reload",
                  debmedia_turn_code: this.callData ? this.callData.code : null,
                  url_origen: window.location.href,
                  origen: "silver " + process.env.PACKAGE_VERSION,
                  mensajes: JSON.stringify({
                    bloblSize: this.sizeChunks,
                    bloblType: e.type,
                  }),
                });

                // reaload
                location.reload();
              }
            }

            // search blobs
            if (this.callData) {

              var item = {
                dni: this.callData?.customer?.dni, // client document
                workstation:
                  this.selectedBranch.id || this.branchesData?.defaultBranch.id,
                user_id: this.loggedUser?.id,
                branch_id: this.selectedBranch?.id,
                turn: this.callData?.letter + this.callData.number,
                created: moment(this.callData?.callTimeStamp).utcOffset("-05:00").unix(), // timestamp
              };

              // Check existe previews blob
              let code = this.callData.code;
              let existBlob;

              // Check if exist same code on serve              
              let respCode = await this.fetchRecorder({
                turn_code: code,
                user: this.loggedUser.id,
                date: moment().format('DD/MM/YYYY')
              });
              respCode = respCode.filter(r => r.status == 'current_code ' + this.version ) ;
              if(!respCode || (respCode && respCode.length < 1 )) existBlob = localStorage.getItem('current_code');
              else {
                existBlob = respCode[0].turn_code
              }

              if (existBlob && existBlob.includes(code) && inital) {

                let codeS = existBlob.split('_');
                code = `${codeS[0]}_${(parseInt(codeS[1]) + 1)}`;

                // let client_data = this.callData;

                // Search blobs saved
                var localBlobsDB = this.dbBlobObject.transaction(["webms"], "readwrite");
                var localBlobs = localBlobsDB.objectStore("webms");
                var requestStop = localBlobs.getAll();

                // blob object  stored no exist maybe
                requestStop.onerror = function (event) {
                  console.log("WEBM LOCAL NO EXIST");
                  console.log(event);
                };

                requestStop.onsuccess = async () => {

                  // Save log recorders
                  this.saveLog({
                    'turn_code': this.callData?.code || 'N/R',
                    'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + code,
                    'status': 'Before seeks recorder from reload ' + this.version,
                  });

                  // Do something with the request.result!
                  let blobs = requestStop.result;
                  console.log("BLOBS Exists")
                  console.log(blobs)
                  // let savedBlobs = blobs.filter(b=>b.name.includes(localStorage.getItem('current_code')) );
                  let savedBlobs = blobs.filter(b => b.name.includes(code.split('_')[0]));

                  // send all blobls save with current turn_code
                  // await new Promise(resolve => {
                  this.sendMultiBlobs(savedBlobs, 0);
                  // });

                  localStorage.setItem('current_code', code);
                  this.saveLog({
                    'turn_code': code,
                    'user': item.user_id,
                    'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + code,
                    'status': 'current_code ' + this.version,
                  });
                  inital = false

                }

              } else {
                if (inital) {
                  inital = false
                }
                if (!existBlob || (existBlob && !existBlob.includes(code))) {
                  code = `${code}_1`;
                  localStorage.setItem('current_code', code);
                  this.saveLog({
                    'turn_code': code,
                    'user': item.user_id,
                    'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + code,
                    'status': 'current_code ' + this.version,
                  });
                  
                  // Save log recorders
                  this.saveLog({
                    'turn_code': this.callData?.code || 'N/R',
                    'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + code,
                    'status': 'Start recorder ' + this.version,
                  });
                } else code = localStorage.getItem('current_code');
              }

              var transaction = this.dbBlobObject.transaction(["webms"], "readwrite");
              var objectStore = transaction.objectStore("webms");
              var request = objectStore.getAll();

              // blob object  stored no exist maybe
              request.onerror = (event) => {
                console.log("WEBM LOCAL NO EXIST");
                console.log(event);

                this.saveLog({
                  'turn_code': this.callData?.code || 'N/R',
                  'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + code,
                  'status': 'WEBM LOCAL NO EXIST (webms) ' + this.version,
                });
              };

              request.onsuccess = () => {
                // Do something with the request.result!
                let blobs = request.result;
                let webMObject = blobs.find(b => b.name == item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + code);

                // before send the file to s3, we save it on the local systemm
                var transaction = this.dbBlobObject.transaction(
                  ["webms"],
                  "readwrite"
                );

                // SAVE ON LOCAL
                // var objectStore = transaction.objectStore("webms");

                // if not blob exist before
                if (!webMObject) {
                  let webM = new Blob([new Blob([], { type: "video/webm" }), e], {
                    type: e.type,
                  });

                  var item2 = {
                    name: item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + code,
                    blob: webM
                  };

                  request = objectStore.add(item2);
                  request.onsuccess = () => {
                    console.log("SAVING... COMPLETE");
                    // Local storage is done
                    transaction.oncomplete = async () => {
                      console.log("Blob all done!");
                    };

                    transaction.onerror = (event) => {
                      console.log("FAIL");
                      console.log(event);
                    };
                  };
                } else {

                  // concat...
                  let webM = new Blob([webMObject.blob, e], {
                    type: e.type,
                  });
                  webMObject.blob = webM;

                  // update the blob
                  request = objectStore.put(webMObject);
                  request.onsuccess = () => {
                    // When local storage is done, we send the file to S3
                    transaction.oncomplete = async () => {
                      console.log("Blob updated!");
                      console.log(webMObject)
                    };

                    transaction.onerror = (event) => {
                      console.log("FAIL UPDATING BLOB");
                      console.log(event);

                      // Save log recorders
                      var item = {
                        dni: this.callData?.customer?.dni, // client document
                        workstation:
                          this.selectedBranch.id || this.branchesData?.defaultBranch?.id,
                        user_id: this.loggedUser.id,
                        branch_id: this.selectedBranch?.id,
                        turn: this.callData?.letter + this.callData?.number,
                        created: moment(this.callData?.callTimeStamp)
                          .utcOffset("-05:00")
                          .unix(), // timestamp
                      };
                      this.saveLog({
                        'turn_code': this.callData?.code || 'N/R',
                        'name': item.created + '/' + item.turn + '/' + (item.dni || '0') + '/' + (item.user_id || '0') + '/' + item.workstation + '/' + item.branch_id + '/' + code,
                        'status': 'Error updating Blob ' + this.version,
                      });

                    };
                  };
                }
              }

              // // preserve
              // if (e.data.size > 0) {
              //   this.generalRecordedChunks.push(e.data);
              // }

              if (this.callData) {
                this.changeMediaRecorder({ state: "recording" });
              }
            };

          },
        };

        this.recorderRTC = RecordRTC(stream, options);

        this.resolveRecorder(true);
      } catch (error) {
        console.error(error);
        this.showRecorderAlert = true;
        this.messageRecorderAlert =
          "Debes dar permisos para compartir el audio y la pantalla";
        this.$nextTick();
      }
    },
    async handlerAllData() {
      this.restartCounterInactivity();
      this.setErrorForce(null);
      await this.getAllData();
    },
    // Check if user is attending
    requetUsertAttendAutoCall() {
      return new Promise((resolve) => {
        this.finalTextDerivate =
          this.environmentData.silver?.autocall.time_wait;
        this.intervalAuto = setInterval(() => {
          if (this.finalTextDerivate < 1) {
            this.finalTextDerivate = 0;
            resolve(false);
            clearInterval(this.intervalAuto);
          }
          this.finalTextDerivate--;
        }, 1000);
        this.acceptTurnAutoMethod = resolve;
      });
    },
    handlecloseCancell() {
      clearInterval(this.intervalAuto);
      // this.$root.countChangeToAbsent
      //   ? this.$root.countChangeToAbsent++
      //   : (this.$root.countChangeToAbsent = 1);
      // this.handlerCancelCall(true);
      this.showModalToCallAuto = false;
      this.finalTextDerivate = this.environmentData.silver?.autocall.time_wait;
      this.acceptTurnAutoMethod(false);
    },
    /**
     * Send file to S3
     */
    async uploadFilesToS3(extension, file, dataTurn, isSync) {
      console.log("Datos turno");
      console.log(dataTurn);
      return new Promise((resolve) => {

        /* eslint-disable */

        this.sendingS3(extension, file, dataTurn, resolve, isSync);

        /* eslint-enable */
      });
    },

    /** Try 3 times if uploading to s3 fail */
    sendingS3(extension, file, dataTurn, resolve, isSync){
      console.log("sync:", isSync)
      var bucketName = "silver-screen-recorders"; // Enter your bucket name

      // Use S3 ManagedUpload class as it supports multipart uploads
      
      if (dataTurn.turn) {

        console.log('INFORMACION DE LA LLAMADA PARA ENVIAR AL S3')
        console.log(dataTurn)

        // Return today's date and time
        var currentTime = new Date()

        // If the process is synchronized, the path and name will be overwritten.
        if(isSync) {
          currentTime = new Date(dataTurn.created * 1000);
        }

        // get values
        let year = currentTime.getFullYear();
        let month = currentTime.getMonth() + 1;
        month = month > 9 ? month : '0' + month;
        let day = currentTime.getDate()
        day = day > 9 ? day : '0' + day;


        // let path = `${year}/${month}/${dataTurn.branch_id}/`;
        // let path = 'videos/' +this.profileData.user.company +'/'
        let delimit = '-';
        let path = process.env.VUE_APP_RECORDER_FOLDER + `/${year}/${month}/${day}/videos/`;
        let turn = `turn=${dataTurn.turn}&turn_id=${dataTurn.turn_id}&branch_name=${dataTurn.branch_name}&branch_id=${dataTurn.branch_id}&created=${dataTurn.created}&year=${year}&month=${month}&turn_code=${dataTurn.code}`;
        let key = path + dataTurn.created + delimit + dataTurn.turn + delimit + (dataTurn.dni || '0') + delimit + (dataTurn.user_id || '0') + delimit + dataTurn.workstation + delimit + dataTurn.branch_id + delimit + (dataTurn.name || localStorage.getItem('current_code')) + extension;


        var upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: bucketName,
            Key: key,
            Body: file,
            Tagging: turn,
            TaggingDirective: 'COPY'
          }
        });

        var promise = upload.promise();

        promise.then(
          () => {

            // Save log recorders
            this.saveLog({
              'turn_code': dataTurn.name || this.callDataTemp.code || 'N/R',
              'name': dataTurn.created + '_' + dataTurn.turn + '_' + (dataTurn.dni || '0') + '_' + (dataTurn.user_id || '0') + '_' + dataTurn.workstation + '_' + dataTurn.branch_id + '_' + (dataTurn.name || localStorage.getItem('current_code')),
              'status': 'Successfully uploaded video ' + this.version,
            });
            console.log("************* Successfully uploaded video. *****************");
            resolve(true);

          },
          (err) => {

            this.trySendCounter++;

            if(this.trySendCounter<3){

              // Error
              this.saveLog({
                'turn_code': dataTurn.name || this.callDataTemp.code || 'N/R',
                'name': dataTurn.created + '_' + dataTurn.turn + '_' + (dataTurn.dni || '0') + '_' + (dataTurn.user_id || '0') + '_' + dataTurn.workstation + '_' + dataTurn.branch_id + '_' + localStorage.getItem('current_code'),
                'status': "There was an error uploading video: " + err.message + ' ' + this.version,
              });
              console.error("There was an error uploading your video: ", err.message);
              this.sendingS3(extension, file, dataTurn, resolve)

            } else {

              // Error
              this.saveLog({
                'turn_code': dataTurn.name || this.callDataTemp.code || 'N/R',
                'name': dataTurn.created + '_' + dataTurn.turn + '_' + (dataTurn.dni || '0') + '_' + (dataTurn.user_id || '0') + '_' + dataTurn.workstation + '_' + dataTurn.branch_id + '_' + localStorage.getItem('current_code'),
                'status': "There was an error, trySendCounter limite was exceded." + ' ' + this.version,
              });

              this.trySendCounter = 0;
              resolve(false);

            };

          }
        );

      } else {

        // Error
        this.saveLog({
          'turn_code': dataTurn.name || this.callDataTemp.code || 'N/R',
          'name': dataTurn.created + '_' + dataTurn.turn + '_' + (dataTurn.dni || '0') + '_' + (dataTurn.user_id || '0') + '_' + dataTurn.workstation + '_' + dataTurn.branch_id + '_' + localStorage.getItem('current_code'),
          'status': "There was an error, dataTurn.turn doesnt exist." + ' ' + this.version,
        });
        console.error("There was an error, dataTurn.turn doesnt exist");
        resolve(false);

      } 
    },

    /**
     * Search and send all video to s3 if sync property not in true
     */
    videosSync() {
      // search files pending... to send to S3
      var transaction = this.dbObject.transaction(["videos"]);
      var objectStore = transaction.objectStore("videos");
      var request = objectStore.getAll();
      request.onerror = function (event) {
        // Handle errors!
        console.log(event);
      };
      request.onsuccess = () => {
        console.log("STORE VIDEOS LIST");
        // Do something with the request.result!
        let videosAll = request.result;
        let videos = videosAll.filter((v) => !v.sync);
        console.log(videos);
        videos.forEach(async (video) => {
          // Send to S3
          this.sendingToS3 = true;

          let result = await this.uploadFilesToS3(".mp4", video.blob, video, true);

          if (result) {
            var objectStore = this.dbObject
              .transaction(["videos"], "readwrite")
              .objectStore("videos");
            var request = objectStore.get(video.id);
            request.onerror = function () {
              console.error("FALLO al actualizar el estado del video");
            };
            request.onsuccess = function () {
              // Get the old value that we want to update
              var data = request.result;

              if (data) {
                // update the value(s) in the object that you want to change
                data.sync = true;

                // Put this updated object back into the database.
                var requestUpdate = objectStore.put(data);
                requestUpdate.onerror = function (event) {
                  console.error("NO se pudo sincronizar");
                  console.log(event);
                  // Do something with the error
                };
                requestUpdate.onsuccess = function () {
                  // Success - the data is updated!
                  console.log(`SE SINCRONIZO EL VIDEO: ${video.name}`);
                };
              } else {
                console.log(`SE SINCRONIZO EL VIDEO: ${video.name}`);
              }
            };
          }
        });

        // // Remove files OLDER 4 DAYS
        // videosAll.map((v) => {
        //   var dt = moment
        //     .unix(String(v.created).slice(0, -3))
        //     .format("YYYY/MM/DD hh:mm A");
        //   let days = moment().diff(moment(dt), "hours");
        //   if (days > 48) {
        //     var objectStoreR = this.dbObject
        //       .transaction(["videos"], "readwrite")
        //       .objectStore("videos");
        //     objectStoreR.delete(v.id);
        //   }
        // });

        // Search all webms saved before
        var localBlobsDB = this.dbBlobObject.transaction(["webms"]);
        var localBlobs = localBlobsDB.objectStore("webms");
        var webmsLocal = localBlobs.getAll();

        // blob object  stored no exist maybe
        webmsLocal.onerror = function (event) {
          console.log("WEBMS LOCAL NO EXIST");
          console.log(event);
        };

        webmsLocal.onsuccess = async () => {

          // Do something with the request.result!
          let webmsLocalR = webmsLocal.result;
          console.log("BLOBS Exists")
          console.log(webmsLocalR)
          
          // send all blobls save with current turn_code
          // await new Promise(resolve => {
          this.sendMultiBlobs(webmsLocalR, 0);
          // });
        }
      };


    },

    soundEmmited() {
      if (this.showModalToCallAuto) document.getElementById("attended").play();
    },

    handleChangeState(val) {
      // enable change work station
      this.$root.enableChangeWorkStation = true;

      // date of change status
      this.dateLastChangedState = new Date();

      // Change during call
      if (this.callData) {
        this.loadingChangeState = true;
        this.loadingChangeStateTimer = setTimeout(() => {
          this.loadingChangeState = false;
          clearTimeout(this.loadingChangeStateTimer);
        }, 1000);
        this.changeState = true;
        if (typeof this.selectOptionState == "object")
          this.objectNewState = this.selectOptionState;
        else
          this.objectNewState = this.optionsSelectInputState.find(
            (valResponse) => valResponse.name === this.selectOptionState
          );
        this.snackbar = true;
        this.text = "Se realizará el cambio de estado al terminar la llamada";
        return;
      }

      // search state selected
      const filteredResponse = this.optionsSelectInputState.find(
        (valResponse) => valResponse.name === val
      );

      if (val == "Conectado - Disponible") {
        this.initPolling();
      }

      if (val == "Desconectado") {
        this.pollingActive = false;
        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function () { },
          Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
          window.clearInterval(i);
        }
      }

      this.restartTimer();
      if (filteredResponse) {
        this.updateStateAction({
          id: filteredResponse.id,
          name: filteredResponse.name,
        });
        // this.selectOptionState = filteredResponse;
      }

      // clearInterval(this.timerPolling);
      clearInterval(this.timer2);
    },

    async initPolling() {

      if (this.pollingActive) return;
      console.log("Inicia polling");
      this.pollingActive = true;

      // check record active
      if (this.environmentData.silver?.recorders) {
        let recorders = Array.isArray(this.environmentData.silver?.recorders)
          ? this.environmentData.silver?.recorders
          : Object.keys(this.environmentData.silver?.recorders).map((r) =>
            parseInt(r)
          );
        if (recorders.includes(this.selectedBranch.id)) {
          if (desktopStream && !desktopStream.active) {
            alert("Debes dar permisos para compartir el audio y la pantalla");
            await this.recordScreen();
          }
        }
      }

      // check Token
      this.checkToken();
      this.pollingWorkplaceGetAction(this.queryGroup);
      this.getCompanyBranchesInfo(
        this.selectedBranch.id || this.branchesData.defaultBranch.id
      );

      // recurrency polling
      setTimeout(() => {
        this.timerPolling = setInterval(() => {
          if (!this.pollingDataLoading) {
            this.pollingWorkplaceGetAction(this.queryGroup);
            this.getCompanyBranchesInfo(
              this.selectedBranch.id || this.branchesData.defaultBranch.id
            );
          }
        }, this.timerNumber);
      }, 1000);
    },

    openVideo() {
      setTimeout(() => {
        this.videoChange(true);
        if (this.$refs.videoCall) this.$refs.videoCall.openVideoCall();
        else this.openVideo();
      }, 1000);
    },

    checkSilverProperty(resolve = null) {
      this.countCheckRecorders++;
      setTimeout(() => {
        if (
          this.environmentData.silver &&
          this.environmentData.silver.recorders
        ) {
          if (resolve) resolve(this.environmentData.silver.recorders);
        } else {
          console.log(this.countCheckRecorders);
          if (this.countCheckRecorders >= 5) {
            resolve({});
          } else this.checkSilverProperty(resolve);
        }
      }, 1000);
    },

    fetchTurnsAttended() {
      // set turns
      if (localStorage.getItem("turns_attended" + this.today)) {
        this.turns_attended = JSON.parse(
          localStorage.getItem("turns_attended" + this.today)
        );
      } else {
        this.turns_attended[this.today] = [];
        localStorage.setItem(
          "turns_attended" + this.today,
          JSON.stringify(this.turns_attended)
        );
      }
    },

    captureAudioPlusScreen() {
      console.log("********** VIDEO PERMISSIONS ********");
      return new Promise((resolve) => {
        navigator.mediaDevices
          .getDisplayMedia({
            video: true,
            audio: true,
          })
          .then((desktopStream) => {
            console.log("desktopStream");
            console.log(desktopStream);

            console.log("getVideoTracks");
            console.log(desktopStream.getVideoTracks());

            let displaySurface = desktopStream
              .getVideoTracks()[0]
              .getSettings().displaySurface;

            console.log("displaySurface");
            console.log(displaySurface);

            // check full screen selected
            if (
              displaySurface != "monitor" &&
              displaySurface != "application"
            ) {
              this.showRecorderAlert = true;
              this.messageRecorderAlert =
                "Debes dar permisos para compartir toda tu pantalla";
              this.$nextTick();
              return;
            }

            let videoConstraint = {
              width: { min: 640, ideal: 1920 },
              height: { min: 400, ideal: 1080 },
              aspectRatio: { ideal: 1.7777777778 },
            };

            // get user media
            navigator.mediaDevices
              .getUserMedia({
                video: videoConstraint,
                audio: true,
              })
              .then((voiceStream) => {
                console.log("voiceStream");
                console.log(voiceStream);

                console.log("voiceStream.getAudioTracks");
                console.log(voiceStream.getAudioTracks());

                if (voiceStream.getAudioTracks().length < 1) {
                  this.showRecorderAlert = true;
                  this.messageRecorderAlert =
                    "Debes dar permisos para compartir el audio y la pantalla";
                  this.$nextTick();
                  return;
                }

                this.addStreamStopListener(desktopStream, async (msg) => {
                  if (msg == 'Error: track.addEventListener - ended') {
                    location.reload();
                  }
                });

                const tracks = [
                  ...desktopStream.getVideoTracks(),
                  ...this.mergeAudioStreams(desktopStream, voiceStream),
                ];

                stream = new MediaStream(tracks);

                resolve(stream);

                // setVideoURL(screenStream, true);
              })
              .catch(function (error) {
                console.log("ERROR: navigator.mediaDevices.getUserMedia");
                console.log(error);
                resolve(false);
              });
          })
          .catch(function (error) {
            console.log(" navigator.mediaDevices.getDisplayMedia");
            console.log(error);
            if (
              error.toString().indexOf("no audio or video tracks available") !==
              -1
            ) {
              alert(
                "RecordRTC failed to start because there are no audio or video tracks available."
              );
            }
            resolve(false);
          });
      });
    },

    addStreamStopListener(stream, callback) {
      stream.addEventListener(
        "ended",
        function () {
          callback("Error: stream.addEventListener - ended");
        },
        false
      );
      stream.addEventListener(
        "inactive",
        function () {
          callback("Error: stream.addEventListener - inactive");
        },
        false
      );
      stream.getTracks().forEach(function (track) {
        track.addEventListener(
          "ended",
          function () {
            callback("Error: track.addEventListener - ended");
          },
          false
        );
        track.addEventListener(
          "inactive",
          function () {
            callback("Error: track.addEventListener - inactive");
          },
          false
        );
      });
    },
    // Merge video and audio
    mergeAudioStreams(desktopStream, voiceStream) {
      try {
        const context = new AudioContext();
        const destination = context.createMediaStreamDestination();

        // If you don't want to share Audio from the desktop it should still work with just the voice.
        const source1 = context.createMediaStreamSource(desktopStream);
        const desktopGain = context.createGain();
        desktopGain.gain.value = 0.7;
        source1.connect(desktopGain).connect(destination);

        const source2 = context.createMediaStreamSource(voiceStream);
        const voiceGain = context.createGain();
        voiceGain.gain.value = 0.7;
        source2.connect(voiceGain).connect(destination);

        return destination.stream.getAudioTracks();
      } catch (error) {
        console.log(error);
        this.showRecorderAlert = true;
        this.messageRecorderAlert =
          "Debes dar permisos para compartir el audio y la pantalla";
        this.$nextTick();
        return;
      }
    },

    // end in error case
    async sendDirectAndFinish(evaluator, service_id, blob) {

      await this.uploadFilesToS3(".mp4", blob, {
        name: this.callDataTemp.letter + this.callDataTemp.number + "_" + this.callDataTemp.id,
        dni: this.callDataTemp.customer?.dni, // client document
        workstation:
          this.selectedBranch.id || this.branchesData.defaultBranch.id,
        user_id: this.loggedUser.id,
        blob: blob,
        turn_id: this.callDataTemp.id + '_' + localStorage.getItem('current_code'),
        branch_name: this.selectedBranch.label,
        branch_id: this.selectedBranch.id,
        turn: this.callDataTemp.letter + this.callDataTemp.number,
        code: this.callData.code,
        created: moment(this.callDataTemp.callTimeStamp)
          .utcOffset("-05:00")
          .unix(), // timestamp
        sync: false,
      });

      this.finishCallData(evaluator, service_id);
    },

    async sendDirect(blob, blobInfo) {

      await this.uploadFilesToS3(".mp4", blob, {
        name: blobInfo.name,
        dni: this.callDataTemp.customer?.dni || blobInfo.dni, // client documend
        workstation:
          this.selectedBranch.id || this.branchesData.defaultBranch.id || blobInfo.workstation,
        user_id: this.loggedUser.id || blobInfo.user_id,
        blob: blob,
        code: blobInfo.name || blobInfo.code,
        turn_id: this.callDataTemp.id + '_' + localStorage.getItem('current_code'),
        branch_name: this.selectedBranch.label || blobInfo.branch_name,
        branch_id: this.selectedBranch.id || blobInfo.branch_id,
        turn: this.callDataTemp.letter + this.callDataTemp.number || blobInfo.turn,
        created: moment(this.callDataTemp.callTimeStamp)
          .utcOffset("-05:00")
          .unix(), // timestamp
        sync: false,
      });

    },

    // send all blob of current code turn
    async sendMultiBlobs(blobs, counter, promise = null) {
      try {

        if (counter >= blobs.length) {
          if (promise) promise();
          return;
        }

        let savedBlob = blobs[counter];

        // to fix video seeking issues
        if (savedBlob.blob) {

          this.getSeekableBlob(savedBlob.blob, async (seekableBlob) => {

            // Save log recorders
            let callSavedData = savedBlob.name.split('_');
            var item = {
              dni: callSavedData[2], // client document
              workstation: callSavedData[4],
              user_id: callSavedData[3],
              branch_id: callSavedData[5],
              code: callSavedData[6],
              turn: callSavedData[1],
              name: callSavedData[6] + '_' + callSavedData[7],
              created: moment(callSavedData[0])
                .utcOffset("-05:00")
                .unix(), // timestamp
            };
            this.saveLog({
              'turn_code': this.callData?.code || 'N/R',
              'name': item.created + '_' + savedBlob.name,
              'status': 'Successfully get seeks from video (sendMultiBlobs) ' + this.version,
            });

            await this.sendDirect(seekableBlob, item);

            var objectBlobStoreR = this.dbBlobObject.transaction(
              ["webms"],
              "readwrite"
            );
            objectBlobStoreR.objectStore("webms").delete(savedBlob.id);
            this.sendMultiBlobs(blobs, counter + 1, promise);

          })
        } else {
          var objectBlobStoreR = this.dbBlobObject.transaction(
            ["webms"],
            "readwrite"
          );
          objectBlobStoreR.objectStore("webms").delete(savedBlob.id);
          this.sendMultiBlobs(blobs, counter + 1, promise);
        }
      } catch (error) {
        console.error(error)
      }

    },

    getSeekableBlob(inputBlob, callback) {

      /* eslint-disable */

      // EBML.js copyrights goes to: https://github.com/legokichi/ts-ebml
      if (typeof EBML === 'undefined') {
        throw new Error('Please link: https://www.webrtc-experiment.com/EBML.js');
      }

      var reader = new EBML.Reader();
      var decoder = new EBML.Decoder();
      var tools = EBML.tools;
      var vue = this;
      var item = {
        dni: vue.callDataTemp.customer?.dni, // client document
        workstation:
          vue.selectedBranch.id || vue.branchesData.defaultBranch.id,
        user_id: vue.loggedUser.id,
        branch_id: vue.selectedBranch.id,
        turn: vue.callDataTemp.letter + vue.callDataTemp.number,
        created: moment(vue.callDataTemp.callTimeStamp)
          .utcOffset("-05:00")
          .unix(), // timestamp
      };

      try {
        var fileReader = new FileReader();
        fileReader.onload = function (e) {
          try {
            var ebmlElms = decoder.decode(this.result);
          } catch (error) {
            console.log("ERROR SEEKS")
            console.log(error)
            // Error
            vue.saveLog({
              'turn_code': vue.callDataTemp.code || 'N/R',
              'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + localStorage.getItem('current_code'),
              'status': "There was an error fetching seeks: " + error + ' ' + this.version,
            });
            // return same blob input
            callback(inputBlob);
          }
          try {
            ebmlElms.forEach(function (element) {
              reader.read(element);
            });
          } catch (error) {
            console.log("ERROR SEEKS")
            console.log(error)
            // Error
            vue.saveLog({
              'turn_code': vue.callDataTemp.code || 'N/R',
              'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + localStorage.getItem('current_code'),
              'status': "There was an error fetching seeks: " + error + ' ' + this.version
            });
            // return same blob input
            callback(inputBlob);
          }
          reader.stop();
          try {
            var refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
          } catch (error) {
            console.log("ERROR SEEKS")
            console.log(error)
            // Error
            vue.saveLog({
              'turn_code': vue.callDataTemp.code || 'N/R',
              'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + localStorage.getItem('current_code'),
              'status': "There was an error fetching seeks: " + error + ' ' + this.version,
            });
            // return same blob input
            callback(inputBlob);
          }
          var body = this.result.slice(reader.metadataSize);
          var newBlob = new Blob([refinedMetadataBuf, body], {
            type: 'video/webm'
          });

          callback(newBlob);
        };
        fileReader.readAsArrayBuffer(inputBlob);
      } catch (error) {
        console.error("FAIL GET SEEKS")
        console.log(error)

        // Error
        vue.saveLog({
          'turn_code': vue.callDataTemp.code || 'N/R',
          'name': item.created + '_' + item.turn + '_' + (item.dni || '0') + '_' + (item.user_id || '0') + '_' + item.workstation + '_' + item.branch_id + '_' + localStorage.getItem('current_code'),
          'status': "There was an error fetching seeks: " + error + ' ' + this.version
        });

        // return same blob input
        callback(inputBlob);
      }

      /* eslint-enable */
    }
  },
};
</script>
  
<style lang="scss" scoped>
$borderWidth: 5px;
$animationTime: 10s;
$border-color-default: rgb(223, 230, 237);
$border-color-progressbar: #50a5eb;
$size: 52px;
$howManySteps: 100; //this needs to be even.

.progress {
  width: $size !important;
  height: $size !important;
  line-height: $size;
  background: none;
  margin: auto;
  box-shadow: none;
  position: relative;
  top: 7px;
  left: 7px;
  background-color: #ffffff !important;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }

  >span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress-left {
    left: 0;
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-progressbar;
  }

  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }

  .progress-right {
    right: 0;

    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }

  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;

    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.colorHouse {
  fill: #00599d;
  color: #00599d;
}

.colorHouseDanger {
  fill: rgb(240, 64, 64);
  color: rgb(240, 64, 64);
}

/* This for loop creates the necessary css animation names
  Due to the split circle of progress-left and progress right, we must use the animations on each side.
  */
@for $i from 1 through $howManySteps {
  $stepName: ($i * (100 / $howManySteps));

  //animation only the left side if below 50%
  @if $i <=($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }

      .progress-left .progress-bar {
        animation: 0;
      }
    }
  }

  //animation only the right side if above 50%
  @if $i >($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        // eslint-disable-next-line prettier/prettier
        // eslint-disable-next-line prettier/prettier
        animation: loading-#{($howManySteps/2)}$animationTime linear forwards; //set the animation to longest animation
      }

      .progress-left .progress-bar {
        animation: loading-#{$i -
          ($howManySteps/2)}
          $animationTime
          linear
          forwards
          $animationTime;
      }
    }
  }
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/ ($howManySteps/2));
  $degrees: ($degrees * $i);

  @keyframes loading-#{$i} {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate($degrees);
      transform: rotate(#{$degrees}deg);
    }
  }
}

.Panel_Video {
  /* width: 100%;
    max-width: 50%;
    max-height: calc(100vh - 179px);
    overflow-y: auto; */
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.Video {
  width: 100%;
  max-width: 85%;
  max-height: calc(100vh - 179px);
  overflow-y: auto;
}

#Video-call {
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 1s;
  animation-iteration-count: 1;
  min-height: 410px;
  opacity: 0;
  width: 100%;
  margin-left: 0.5rem;
}

.badge-dk {
  max-width: fit-content;
  padding: 1rem;
  transition: 0.5s all;
}

.badge-dk h1 {
  margin: 0;
  font-size: 1.3rem;
}

.badge-dk h2 {
  font-size: 1rem;
  border-bottom: 1px solid gray;
  text-align: start;
  padding: 0.8rem;
}

.badge {
  padding: 0.2rem;
  border: 1px solid gray;
  border-radius: 4px;
  margin: 0 0.5rem;
}

/* .badge-dk-video {
    max-width: 100% !important;
    margin-right: 0 !important;
    transition: 0.5s all;
  }
  .dk-group-video {
    flex-wrap: wrap;
    margin-right: 0 !important;
    transition: 0.5s all;
  } */

.Panel {
  max-height: 100%;
  /* overflow-y: auto; */
  flex: 1;
}

.nth-2-video-badge,
.nth-3-video-badge {
  min-height: 550px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    transform: translateX(500px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 1372px) {
  .GridColumns {
    width: 100%;
  }

  .dk-group-video {
    flex-wrap: wrap;
    justify-content: center;
  }

  .yoshua_video {
    max-height: 100% !important;
  }

  .dk-group-flex {
    flex-wrap: wrap;
    justify-content: center;
  }

  /* .Panel,
    .Panel_Video {
      max-width: 100%;
    } */

  .Panel_Video {
    flex-wrap: wrap;
    min-width: 100%;
    justify-content: start;
  }

  .badge-dk {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    transition: 0.5s all;
  }

  .dk-group div:nth-child(1) {
    border-radius: 4px 4px 0 0;
  }

  .dk-group div:nth-child(2) {
    border-radius: 0 0 4px 4px;
  }

  .dk-group {
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    transition: 0.5s all;
  }

  .badge-dk-video {
    max-width: initial !important;
    margin: 0 !important;
  }

  .scroll-area {
    max-height: 100% !important;
  }
}

.ContainerCards {
  display: grid;
  grid-template-columns: 60px 1fr;
  justify-items: flex-end;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 350px;
}

.scroll-area-video {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 300px;
  /* overflow: auto; */
}

.dk-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.dk-header button {
  position: absolute;
  right: 2px;
  top: 0;
}

/* .GridColumns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 2fr 200px 1fr;
  } */
</style>
